import { Col, Container, Row } from "react-bootstrap";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Autoplay } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import { useRouter } from "next/router";
import { Fragment } from "react";
import AnchorLink from "../anchor/Anchor";
import { useWindowDimensions } from "../../utils";
import Image from "next/image";

const Slider = ({ sliders }) => {
  const { locale } = useRouter();
  const revLocale = locale === "en" ? "en" : "tr";
  const { width } = useWindowDimensions();
  return (
    <div className="slider-main slider_main">
      <Swiper
        slidesPerView={"auto"}
        navigation={{ clickable: true }}
        loop={true}
        spaceBetween={15}
        centeredSlides={true}
        className="slider_main"
        autoplay={{
          delay: 4500,
          disableOnInteraction: false,
        }}
        lazyPreloadPrevNext={0}
        modules={[Navigation, Pagination, Autoplay]}
      >
        {sliders?.map((value, index) => (
          <Fragment key={index}>
            {value?.lang === locale && (
              <SwiperSlide key={index} id={value.id}>
                <div className="slider_bg">
                  <Image
                    src={
                      width
                        ? width > 767
                          ? value.bg
                          : value.mobileBg
                        : value.bg
                    }
                    fill
                    sizes="(min-width: 808px) 50vw, 100vw"
                    style={{
                      objectFit: "cover", // cover, contain, none
                    }}
                    priority={index === 0}
                    loading={index === 0 ? "eager" : "lazy"}
                    alt={value.text}
                  />
                </div>

                <div className="slider_inner">
                  <Container className="h-100">
                    <Row className="justify-content-center h-100">
                      <Col md={12} className="h-100">
                        <div
                          className={` d-flex flex-wrap h-100 align-content-center`}
                        >
                          <div className="position-relative text-center w-100">
                            <div style={{ width: "100%" }}>
                              <h4
                                className="slider_title"
                                dangerouslySetInnerHTML={{ __html: value.text }}
                              ></h4>
                              {value.detail && (
                                <AnchorLink
                                  locale={revLocale}
                                  href={value.url}
                                  className={`btn btn-outline-light`}
                                  target={value.target ? "_blank" : null}
                                >
                                  <span>
                                    <strong> Detaylı Bilgi </strong>{" "}
                                  </span>
                                </AnchorLink>
                              )}
                            </div>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </Container>
                </div>
              </SwiperSlide>
            )}
          </Fragment>
        ))}
      </Swiper>
    </div>
  );
};

export default Slider;
