import dynamic from "next/dynamic";
import { useState, Fragment, useCallback, useEffect, useRef } from "react";
import { Container } from "react-bootstrap";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation, Autoplay } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import { LazyLoadImage } from "react-lazy-load-image-component";
import Image from "next/image";

const Title = dynamic(() => import("../title/Title"), {
  ssr: false,
});
const AnchorLink = dynamic(() => import("../anchor/Anchor"), {
  ssr: false,
});

const useMediaQuery = (width) => {
  const [targetReached, setTargetReached] = useState(false);

  const updateTarget = useCallback((e) => {
    if (e.matches) {
      setTargetReached(true);
    } else {
      setTargetReached(false);
    }
  }, []);

  const mediaMatch = () => {
    const media = window.matchMedia(`(max-width: ${width}px)`);
    media.addListener(updateTarget);

    if (media.matches) {
      setTargetReached(true);
    }

    return () => media.removeListener(updateTarget);
  };

  useEffect(() => {
    mediaMatch();
  }, []);

  return targetReached;
};

const News = ({ contentList, headerText, styleSection, pathname }) => {
  const sm = useMediaQuery(425);
  const sliderRef = useRef(null);

  const handlePrev = useCallback(() => {
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slidePrev();
  }, []);

  const handleNext = useCallback(() => {
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slideNext();
  }, []);
  return (
    <div className={styleSection}>
      <Title TitleText={headerText} />
      <Container className="mt-md-5 mt-3">
        <div className="slider-news-wrapper">
          <Swiper
            ref={sliderRef}
            slidesPerView={sm ? 1 : 3}
            spaceBetween={30}
            loop={true}
            centeredSlides={false}
            pagination={{ clickable: true }}
            modules={[Navigation, Pagination, Autoplay]}
            key="1"
            loading="lazy"
            lazyPreloadPrevNext={true}
            breakpoints={{
              640: {
                slidesPerView: 1,
                spaceBetween: 20,
              },
              821: {
                slidesPerView: 2,
                spaceBetween: 40,
              },
              1024: {
                slidesPerView: 3,
                spaceBetween: 50,
              },
            }}
          >
            {contentList &&
              contentList.map((item, index) => (
                <Fragment key={index}>
                  {!item.isDeleted && (
                    <SwiperSlide key={index}>
                      <AnchorLink
                        href={"/" + pathname + "/" + item.seoUrl}
                        className="slider_news  slider-news"
                      >
                        <div className="slider_news_img">
                          <Image
                            src={
                              item?.pictureUrl
                                ? process.env.IMAGE_URL + item?.pictureUrl
                                : "/logo.svg"
                            }
                            width={320}
                            height={214}
                            alt={item.title}
                          />
                        </div>
                        <div className="slider_news_body">
                          <p className="slider_news_title">{item.title}</p>
                        </div>
                      </AnchorLink>
                    </SwiperSlide>
                  )}
                </Fragment>
              ))}
          </Swiper>
          <div className="slider-navigation">
            <div onClick={handlePrev} className=" slider-navigation-prev">
              <i className="icon-angle-left" />
            </div>
            <div onClick={handleNext} className=" slider-navigation-next">
              <i className="icon-angle-right" />
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default News;
